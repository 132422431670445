import * as React from 'react';
import { Button, Collapse } from '@msdyn365-commerce-modules/utilities';

export interface ICustomCollapseProps {
    content: string;
    title: string;
    type: string;
    isOpen: boolean;
    toggle(): void;
}
/**
 *
 * The CustomCollapse component renders the title and its items.
 * @extends {React.PureComponent<ICustomCollapseProps>}
 */

class CustomCollapse extends React.Component<ICustomCollapseProps> {
    constructor(props: ICustomCollapseProps) {
        super(props);
    }

    public render(): JSX.Element | null {
        return (
            <div className='main-collapse'>
                <div className='accordian-container'>
                    <div className='accordian-item'>
                        <Button
                            className={this.props.isOpen ? 'ms-refine-submenu__toggle_expanded' : 'ms-refine-submenu__toggle_collapsed'}
                            onClick={this.props.toggle}
                            aria-expanded={this.props.isOpen}
                        >
                            {this.props.title}
                        </Button>
                        <Collapse isOpen={this.props.isOpen} timeout={100}>
                            {this.props.content}
                        </Collapse>
                    </div>
                </div>
            </div>
        );
    }
}
export default CustomCollapse;

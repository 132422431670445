import { IBuyboxViewProps } from '@msdyn365-commerce-modules/buybox/dist/types';
import { IBuyboxResources } from '@msdyn365-commerce-modules/buybox/dist/types/index';
import { reaction } from 'mobx';
import * as React from 'react';
interface IBuyboxDimensionProps {
    isSizeVariantAvailable: boolean;
}
interface IBuyboxDimensionState {
    colorName: string;
    selectedSize: string;
}

/**
 *
 * BuyboxDimensions component
 * @extends {React.PureComponent<IBuyboxViewProps & IBuyboxDimensionProps>}
 */
export class BuyboxDimensions extends React.PureComponent<IBuyboxViewProps & IBuyboxDimensionProps, IBuyboxDimensionState> {
    constructor(props: IBuyboxViewProps & IBuyboxDimensionProps) {
        super(props);
        this.state = {
            colorName: '',
            selectedSize: ''
        };
    }

    public componentDidMount() {
        const {
            data: {
                productDimensions: { result: productDimensions }
            }
        } = this.props;

        productDimensions &&
        productDimensions.map(async dimension => {
                if (dimension.DimensionValues && dimension.DimensionValues.length === 1 && dimension.DimensionTypeValue === 1) {
                    await this.props.callbacks.dimensionSelectedAsync(
                        dimension.DimensionTypeValue,
                        dimension.DimensionValues[0].RecordId.toString()
                    );
                }
            });
        reaction(
            () => this.props.data?.productDimensions && this.props.data?.productDimensions?.result?.length,
            () => {
                if(this.props.data?.productDimensions && this.props.data?.productDimensions?.result && this.props.data?.productDimensions?.result?.length > 0){
                    this.props.data?.productDimensions?.result?.map(dimen => {
                        if(this.props.callbacks.initializeDimension){
                        let size = dimen?.DimensionTypeValue.toString()
                            this.props.callbacks.initializeDimension(size)
                        }
                    })
                }
            }
        );
    }

    public render(): JSX.Element {
        const {
            data: {
                productDimensions: { result: productDimensions }
            },
            context: {
                request: {
                    apiSettings: { baseImageUrl }
                },
                app: {
                    config: { enableStockCheck }
                }
            },
            resources,
            callbacks,
            isSizeVariantAvailable
        } = this.props;
        if(!this.state.colorName) {
            productDimensions && productDimensions.map(async dimension => {
                if (dimension.DimensionValues && dimension.DimensionValues.length === 1 && dimension.DimensionTypeValue === 1) {
                    await this.props.callbacks.dimensionSelectedAsync(
                        dimension.DimensionTypeValue,
                        dimension.DimensionValues[0].RecordId.toString()
                    );
                }
            });
        }
        let selectedSize: string | null | undefined = this.state.selectedSize;
        let selectedColorName: string | null | undefined = this.state.colorName;
        return (
            <div className='dimension-swatch'>
                {productDimensions
                    ? productDimensions.map((dimension, i) => {
                          const swatchName = this._getDropdownName(dimension.DimensionTypeValue, resources).toLowerCase();
                          if(dimension.DimensionTypeValue === 3) {
                            if(dimension.DimensionValue?.Value) {
                                selectedSize = dimension.DimensionValue?.Value;
                            } else {
                                dimension.DimensionValues?.map((dv, i) => {
                                    if(dv.DimensionId?.toString() === selectedSize) {
                                        selectedSize = dv.Value;
                                    }
                                });
                            }
                          }
                          if(dimension.DimensionTypeValue === 1) {
                            if(dimension.DimensionValue?.Value) {
                                selectedColorName = dimension.DimensionValue?.Value;
                            } else {
                                dimension.DimensionValues?.map((dv, i) => {
                                    if(dv.DimensionId?.toString() === selectedColorName) {
                                        selectedColorName = dv.Value;
                                    }
                                });
                            }
                          }
                          return (
                              <div className={`custom-${swatchName}-wrap`} key={i}>
                                  {dimension.DimensionValue && dimension.DimensionValue.Value ? (
                                      <div className='swatch-heading'>
                                          Selected {callbacks.getDropdownName(dimension.DimensionTypeValue, resources).toLowerCase()}:{' '}
                                          <span>{dimension.DimensionValue.Value} </span>
                                          {dimension.DimensionTypeValue === 3 && !isSizeVariantAvailable && enableStockCheck && (
                                              <span className='outofstock-text'>Out of stock</span>
                                          )}
                                      </div>
                                  ) : (
                                      <div className='swatch-heading'>
                                          {/* VSI-Customization Display Lables based on Selection of different dimensions */}
                                          {dimension.DimensionTypeValue === 1
                                              ? this.state.colorName
                                                  ? 'Selected'
                                                  : 'Select'
                                              : dimension.DimensionTypeValue === 3
                                              ? this.state.selectedSize
                                                  ? 'Selected'
                                                  : 'Select'
                                              : ''}{' '}
                                          {callbacks.getDropdownName(dimension.DimensionTypeValue, resources).toLowerCase()}:{' '}
                                          {dimension.DimensionTypeValue === 1 ? selectedColorName : selectedSize}
                                      </div>
                                  )}
                                  <ul className={`custom-swatches ${swatchName}-swatch`}>
                                      {dimension.DimensionValues
                                          ? dimension.DimensionValues.map((dimensionValue, index) => {
                                                const dimensionItemId = dimensionValue.DimensionId!.toString();
                                                //selected dimension customization
                                                let sizeQuery;
                                                let colorQuery;
                                                try {
                                                    if (new URLSearchParams(window?.location?.search).get('size')) {
                                                        sizeQuery = new URLSearchParams(window?.location?.search).get('size');
                                                        // VSI-Customization Update State regarding selected SelectedSize
                                                        this.setState({ selectedSize: sizeQuery! });
                                                    }
                                                    if (new URLSearchParams(window?.location?.search).get('color')) {
                                                        colorQuery = new URLSearchParams(window?.location?.search).get('color');
                                                        // VSI-Customization Update State regarding selected ColorName
                                                        this.setState({ colorName: colorQuery || '' });
                                                    }
                                                } catch (error) {}
                                                // const selectedValue =
                                                //   (dimension.DimensionValue &&
                                                //     dimension.DimensionValue.Value) ||
                                                //   "not available";

                                                //selected dimension customization

                                                //commented current implementation
                                                // const isActive = selectedValue === dimensionValue.Value;
                                                const isActive =
                                                    sizeQuery?.toLowerCase() === dimensionValue.Value?.toLowerCase() ||
                                                    sizeQuery === dimensionValue.DimensionId?.toString() ||
                                                    colorQuery?.toLowerCase() === dimensionValue.Value?.toLowerCase() ||
                                                    colorQuery === dimensionValue.DimensionId?.toString();

                                                const onlyOneVariant =
                                                    dimension.DimensionValues &&
                                                    dimension.DimensionValues.length === 1 &&
                                                    dimension.DimensionTypeValue === 1
                                                        ? true
                                                        : false;

                                                const backgroundImageUrl = `url(${baseImageUrl}Products/${dimensionItemId}.png)`;

                                                return (
                                                    <li
                                                        key={index}
                                                        style={{
                                                            backgroundImage: backgroundImageUrl,
                                                            backgroundColor: '#FFF'
                                                        }}
                                                        className={
                                                            isActive || onlyOneVariant
                                                                ? `selected uniform-image-${swatchName}`
                                                                : `uniform-image-${swatchName}`
                                                        }
                                                        onClick={this.generateOnClick(
                                                            dimension.DimensionTypeValue,
                                                            dimensionValue.RecordId.toString()
                                                        )}
                                                        role='button'
                                                    >
                                                        <button>{dimensionValue.Value}</button>
                                                    </li>
                                                );
                                            })
                                          : false}
                                  </ul>
                              </div>
                          );
                      })
                    : false}
            </div>
        );
    }
    private generateOnClick = (dimensionTypeValue: number, dimensionId: string) => {
        return async () => {
            await this.props.callbacks.dimensionSelectedAsync(dimensionTypeValue, dimensionId);
        };
    };

    private _getDropdownName = (dimensionType: number, resources: IBuyboxResources): string => {
        switch (dimensionType) {
            case 1: // ProductDimensionType.Color
                // return resources.productDimensionTypeColor;
                return 'color';
            case 2: // ProductDimensionType.Configuration
                return resources.productDimensionTypeConfiguration;
            case 3: // ProductDimensionType.Size
                return resources.productDimensionTypeSize;
            case 4: // ProductDimensionType.Style
                return resources.productDimensionTypeStyle;
            default:
                return '';
        }
    };
}

export default BuyboxDimensions;
